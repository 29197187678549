import React, { useState, useEffect } from 'react'
import { getItem} from "../services/session"
import { navigate } from "gatsby"
import { getUser} from "../services/auth"

const InformeVisita = props => {
  const [granjaStringSelected, setGranjaStringSelected] = useState("");
  const fileUrlTest="http://files.granjatec.net"
  const fileUrl="https://files.granjatec.online"
  
  const handleBack = (e) => {
    e.preventDefault();
    navigate("/veterinaris/informes")    
  }
 

  useEffect( () => {
    if (!getUser() || getUser().nivell.veterinari!==1) {
        navigate("../../mainMenu") 
      }
    let granja= getItem("userGranjaTecGranja");
    setGranjaStringSelected(granja.granjaStringSelected)
  }, []);
  
  return (
    
    <div className="steps-panel last">
        <div className="steps-panel-scroller">
        <div className="form-summary">
            <div className="multicheck-label">
                <span className="multicheck-label-main">Nom de la granja</span> : <span className="multicheck-label-value">{granjaStringSelected}</span>
            </div>
            <div className="multicheck-label">
                <span className="multicheck-label-main">Data inspecció</span> : <span className="multicheck-label-value">
                    {props.data.data?
                        <span> {props.data.data.slice(0,10)} {props.data.data.slice(11,16)}</span>
                    :
                    <span> {props.data.dataInspeccio}</span>
                    }
                </span>
            </div>
            <div className="multicheck-label">
                <span className="multicheck-label-main">T&iacute;tol</span> : <span className="multicheck-label-value">{props.data.titol}</span>
            </div>
            <div className="multicheck-label">
                <span className="multicheck-label-main">Observacions</span> : <span className="multicheck-label-value">{props.data.observacions}</span>
            </div>
            {props.data.families ? 
                <div className="multicheck-label-group">
                    <span className="multicheck-label-main">Families</span>
                  
                    {props.data.families.map((fam) => (
                        <div key={fam} className="multicheck-label">
                            <div key={fam} className="multicheck-families">
                                <div key={fam} className="multicheck-families-title">{fam.descripcio}</div>
                                { fam.subfamilies.map((subfam) => (
                                    <span key={subfam}>
                                        <div className="value">{subfam.dessubfamilia}</div>
                                    </span>
                                ))}
                            </div>
                        </div>
                    ))
                    }
                </div>
                : <></>
            }
            <div className="multicheck-label">
                <span className="multicheck-label-main">Arxius pujats</span>
                <ul>
                    {props.data.pdf1 ? 
                    <li> {(!props.operation)?    (
                        <a target="_blank" href={`${fileUrl}/${props.data.pdf1}`}> {props.data.pdf1}</a>
                       ):  <span>{props.data.pdf1}</span> }
                    </li>
                    :''
                    } 
                   {props.data.pdf2 ? 
                    <li> {(!props.operation)?    (
                        <a target="_blank" href={`${fileUrl}/${props.data.pdf2}`}> {props.data.pdf2}</a>
                       ):  <span>{props.data.pdf2}</span> }
                    </li>
                    :''
                    } 
                </ul>
            </div>
            <div className="multicheck-label">
                <span className="multicheck-label-main">Fotos pujades</span>
                <ul>
                   {props.data.foto1 ? 
                    <li> {(!props.operation)?    (
                        <a target="_blank" href={`${fileUrl}/uploads/${props.data.foto1}`}> {props.data.foto1}</a>
                       ):  <span>{props.data.foto1}</span> }
                    </li>
                    :''
                    } 
                    {props.data.foto2 ? 
                    <li> {(!props.operation)?    (
                        <a target="_blank" href={`${fileUrl}/${props.data.foto2}`}> {props.data.foto2}</a>
                       ):  <span>{props.data.foto2}</span> }
                    </li>
                    :''
                    } 
                   {props.data.foto3 ? 
                    <li> {(!props.operation)?    (
                        <a target="_blank" href={`${fileUrl}/uploads/${props.data.foto3}`}> {props.data.foto3}</a>
                       ):  <span>{props.data.foto3}</span> }
                    </li>
                    :''
                    } 
                    {props.data.foto4 ? 
                    <li> {(!props.operation)?    (
                        <a target="_blank" href={`${fileUrl}/uploads/${props.data.foto4}`}> {props.data.foto4}</a>
                       ):  <span>{props.data.foto4}</span> }
                    </li>
                    :''
                    } 
                </ul>
            </div>
        </div> 
        <div className="form-submit-wrapper">
            {(props.operation)?
              (
                <>
                <button className="btn-secondary btn--xs steps-nav-init"  onClick={props.cancel}>Modificar</button>
                <button className="btn-secondary btn--xs steps-nav-init" onClick={props.confirm}>Finalitzar visita</button>
                </>
              ):
              <button className="btn-secondary btn--xs steps-nav-init" onClick={handleBack}>Tornar</button>
              }
        </div>
        <div className="pagination"></div>
    </div>
    </div>
  )}

  export default InformeVisita