import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group';
import classNames from "classnames"
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getItem} from "../services/session"
import Alert from "./alert"
 
const TransitionInspeccio = props => {
  const [pdf1, setPdf1] = useState([]);
  const [pdf2, setPdf2] = useState([]);
  const [foto1, setFoto1] = useState([]);
  const [foto2, setFoto2] = useState([]);
  const [foto3, setFoto3] = useState([]);
  const [foto4, setFoto4] = useState([]);
  const [observacions, setObservacions] = useState("");
  const [titol, setTitol] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startDateString, setStartDateString] = useState("");
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  
  const buildForm=(callback)=> {
    const formData = new FormData();
    if (pdf1) formData.append('pdf1', pdf1);
    if (pdf2)  formData.append('pdf2', pdf2);
    if (foto1) formData.append('foto1', foto1);
    if (foto2) formData.append('foto2', foto2);
    if (foto3) formData.append('foto3', foto3);
    if (foto4) formData.append('foto4', foto4);
    const data={}
    let _granja= getItem("userGranjaTecGranja");
    data["granja"]=_granja.granjaSelected;
    data["granjaStringSelected"]=_granja.granjaStringSelected;
    data["titol"]=titol
    data["observacions"]=observacions
    data["dataInspeccio"]=startDateString
    data["pdf1"]=(pdf1)?pdf1.name:""
    data["pdf2"]=(pdf2)?pdf2.name:""
    data["foto1"]=(foto1)?foto1.name:""
    data["foto2"]=(foto2)?foto2.name:""
    data["foto3"]=(foto3)?foto3.name:""
    data["location"]=latitude+","+longitude;

    let _visites= getItem("userGranjaTecVisites");
    let _visitesFinal=new Map();
    var familiesArray = [];
    // build results
    for (var i=0;i<_visites.length;i++) {
 
      for (var j=0;j<_visites[i].subfamilies.length;j++){
       
         if (_visites[i].subfamilies[j].checked) {
          //console.log("subfamilia a tractar ----" +JSON.stringify(_visites[i].subfamilies[j]))
          //guardar la familia i afegir la subfamilia
          let _fam = _visitesFinal.get(_visites[i].id_familia);
          //console.log("existeix la familia? "+_visites[i].id_familia+ "al map "+JSON.stringify(_visites, (key, value) => (value instanceof Map ? [...value] : value)))
          if (!_fam) {
            _fam={};
            _fam["descripcio"]=_visites[i].descripcio;
            _fam["id_familia"]=_visites[i].id_familia;
            _fam["subfamilies"]=[];
          }
          _fam.subfamilies.push({
              "dessubfamilia": _visites[i].subfamilies[j].dessubfamilia,
              "id_subfamilia": _visites[i].subfamilies[j].id_subfamilia
           })
           let item=[]
           item.push(_visites[i].id_familia)
           item.push(_visites[i].subfamilies[j].id_subfamilia)
           familiesArray.push(item);
           //console.log("subfamilies="+_fam.subfamilies)
           _visitesFinal.set(_visites[i].id_familia,_fam)
        }
      }
    }
    const values = Array.from(_visitesFinal.values());
    //console.log("map final ="+JSON.stringify(_visitesFinal, (key, value) => (value instanceof Map ? [...value] : value)))
    //console.log("values del map final="+values)
    //console.log("array famlies final = "+familiesArray.toString())
    data["families"]=values;
    formData.append("families",familiesArray)
    callback(data,formData);
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }
  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  const onChangePdf1 =(e) => {
    setPdf1(e.target.files[0]);
  }
  const onChangePdf2 =(e) => {
    console.log("files2="+e.target.files[0])
    setPdf2(e.target.files[0]);
  }
  const onChangeFoto1 =(e) => {
    console.log("files1="+e.target.files[0])
    setFoto1(e.target.files[0]);
  }
  const onChangeFoto2 =(e) => {
    setFoto2(e.target.files[0]);
  }
  const onChangeFoto3 =(e) => {
    setFoto3(e.target.files[0]);
  }
  const onChangeFoto4 =(e) => {
    setFoto4(e.target.files[0]);
  }
  const handleObservacions = (e) => {
    setObservacions(e.target.value)
  }
  const handleTitol = (e) => {
    setTitol(e.target.value)
  }
  const handleStartDate = data => {
   let dataTime=formatDate(data) 
   setStartDate(data);
   setStartDateString(dataTime);
  }
  const handleClickUp = (e) => {
    e.preventDefault();
    buildForm(function(data,form,err){
      if (err){
        setMessageAlertStep("Error al pujar fitxers")
        console.log("err="+err)
        setShowAlertStep(true)
        return
      }else {
       props.callbackValues(form,data)
       props.handleStepActiveUp(props.stepActive)
      }
    })
    
  }
  const handleClickDown = (e) => {
    e.preventDefault();
    //console.log("pdf?"+pdf1 ||pdf2 ||foto1 || foto2 ||foto3 ||foto4)
    if (pdf1 ||pdf2 ||foto1 || foto2 ||foto3 ||foto4) {
      var res=window.confirm('Atenció, es perdran els fitxers que has pujat') 
      if (res) props.handleStepActiveDown(props.stepActive)
    }else props.handleStepActiveDown(props.stepActive)
  }

  useEffect( () => {
    var data =new Date();
    let dataTime=formatDate(data) 
    if (!startDateString || startDateString==="") setStartDateString(dataTime)
    setStartDate(data);

    navigator.geolocation.getCurrentPosition(
      function(position) {
          // alert("Lat: " + position.coords.latitude + "\nLon: " + position.coords.longitude);
           setLatitude(position.coords.latitude)
           setLongitude(position.coords.longitude)
      },
      function(error){
         // alert(error.message);
      }, {
           enableHighAccuracy: true
                ,timeout : 5000
      }
  );
    
  }, []);

  return (
      <span>
        <CSSTransition
          in={props.stepActive === props.index}
          timeout={300}
          classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
          unmountOnExit>
        <div className="steps-panel">
            <div className="steps-panel-scroller">
              <div className={'field-wrapper field-wrapper-datepicker dirty'}>
                <DatePicker dateFormat="dd/MM/yyyy" maxDate={new Date()} selected={startDate} name="data" onChange={handleStartDate} />
                <label htmlFor="date">Data Inspecció</label>
              </div>
              <div className="field-wrapper">
                  <textarea className={classNames(titol && 'dirty', 'field-input')} name="titol" max="100" onChange={handleTitol} value={titol}></textarea>
                  <label htmlFor="titol">Títol revisió</label>
              </div> 
              <div className="field-wrapper">
                  <textarea className={classNames(observacions && 'dirty', 'field-input')} name="observacions" max="250" onChange={handleObservacions} value={observacions}></textarea>
                  <label htmlFor="observacions">Observacions</label>
              </div>  
              <div className="field-wrapper field-wrapper-file">
                  <input className="field-input dirty" type="file" name="pdfFile1" onChange={onChangePdf1} />
                  <label htmlFor="pdf1">Pdf File 1</label>
                  <span>{pdf1.name}</span>              
              </div>
              <div className="field-wrapper field-wrapper-file">
                  <input className="field-input dirty" type="file" name="pdfFile2" onChange={onChangePdf2} />
                  <label htmlFor="pdf2">Pdf File 2 </label>
                  <span>{pdf2.name}</span>
               </div>
              <div className="field-wrapper field-wrapper-file">
                  <input className="field-input dirty" type="file" name="foto1" onChange={onChangeFoto1} />
                  <label htmlFor="foto1">Foto 1 </label>  
                  <span>{foto1.name}</span>                
              </div>
              <div className="field-wrapper field-wrapper-file">
                  <input className="field-input dirty" type="file" name="foto2" onChange={onChangeFoto2} />
                  <label htmlFor="foto2">Foto 2 </label>
                  <span>{foto2.name}</span>                  
              </div>
              <div className="field-wrapper field-wrapper-file">
                  <input className="field-input dirty" type="file" name="foto3" onChange={onChangeFoto3} />
                  <label htmlFor="foto3">Foto 3 </label>
                  <span>{foto3.name}</span>
               </div>
               <div className="field-wrapper field-wrapper-file">
                  <input className="field-input dirty" type="file" name="foto4" onChange={onChangeFoto4} />
                  <label htmlFor="foto4">Foto 4 </label>
                  <span>{foto4.name}</span>
               </div>
               <div className="pagination"></div>
            </div>
            <div className="steps-nav">
                <button onClick={handleClickDown} className={props.stepActive <= 1 ? classNames('disabled', 'steps-nav-btn', 'steps-nav-btn-prev') : classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
                  <span>Prev</span>
                </button>
                <button onClick={handleClickUp} className={props.stepActive >= 5 ? classNames('disabled', 'steps-nav-btn steps-nav-btn-next') : 'steps-nav-btn steps-nav-btn-next'}>
                  <span>Next</span>
                </button>
            </div>
        </div>

        </CSSTransition>

        <CSSTransition
          in={showAlertStep}
          timeout={300}
          classNames={'alertright'}
          unmountOnExit>

            <Alert type="error">
              <div className="alert-message">
                  {messageAlertStep}
              </div>
              <span className="alert-close" onClick={closeAlertStep}>
                 <i className="icon-close"></i>
              </span>
            </Alert>
      </CSSTransition>
      </span>

    )
}

export default TransitionInspeccio