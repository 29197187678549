import React, { useState, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group';
import { getItem} from "../services/session"
import InformeVisita from "./informeVisita";

const TransitionInspeccioResum = props => {
 
  const handleClickDown = (e) => {
    e.preventDefault();
    props.handleStepActiveDown(props.stepActive)
  }

  
  useEffect( () => {

    let granja= getItem("userGranjaTecGranja");
    
  }, []);

  return (
        <CSSTransition
          in={props.stepActive === props.index}
          timeout={300}
          classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
          unmountOnExit
        >
        <InformeVisita data={props.arrayData} confirm={props.handleConfirm} cancel={handleClickDown} operation={true}/>
        </CSSTransition>

    )
}

export default TransitionInspeccioResum