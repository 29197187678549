import React, { useState, useEffect } from 'react'
import { navigate} from "gatsby"
import Header from "../../components/header"
import HeaderMenu from "../../components/header-menu"
import Footer from "../../components/footer"
import Alert from "../../components/alert"
import { CSSTransition } from 'react-transition-group';
import { getUser} from "../../services/auth"
import {useEmpreses} from "../../helpers/useEmpreses"
import TransitionFamilies from "../../components/transitionFamilies"
import TransitionSubFamilies from "../../components/transitionSubFamilies"
import TransitionInspeccio from "../../components/transitionInspeccio"
import TransitionInspeccioResum from "../../components/transitionInspeccioResum"
import {setItem,removeItem} from "../../services/session"
import axios from 'axios';

const Visita =() => {

  var empreses=useEmpreses()
  //Step controls
  const [stepActive, setStepActive] = useState(1);
  const [stepPrev, setStepPrev] = useState(0);
  const [families, setFamilies] = useState([]);
  const [familia, setFamilia ] =  useState([]);
  const [data, setData] = useState([]);
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  
  const resetStepActive = event => {
    event.preventDefault();
    setStepActive(1);
  }
  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  const getSubFamilies = (idFamilia)=> {
    console.log("getSubFamilia de = "+idFamilia)
    // si té familia seleccionada, guardem familia i subfamilies, sino, enviem totes les families 
    var fam=[]
    //cal buscar la familia a l'array.
    for (var i=0;i<families.length;i++){
      console.log("idfamilia bucle="+families[i].id_familia+", idFamilia "+idFamilia)
        if (families[i].id_familia==idFamilia) {
          fam.push(families[i])
          setFamilia(fam)
          console.log("i trobada="+i)
          break;
        }
    }
    console.log("i seleccionada= "+i+", length?"+families.length)
    if (i==families.length) setFamilia(families)
    console.log("familia seleccionada ="+JSON.stringify(familia))
    
  }

  const initVisites =() => {
    let _families=getEmpresa(getUser().idEmpresa).families;
    setItem("userGranjaTecVisites",_families);
  }

  const handleStepUp = (value) => {
   if (value!==5) {
      setStepPrev(value);
      setStepActive(value + 1);
   }
  }

  const handleStepDown = (value) => {
     if (value!==1) {
      setStepPrev(value);
      setStepActive(value - 1);
     }
  }

  const handleFormData = (form,data) => {
    setFormData(form)
    setData(data)
  }

  const getEmpresa =(idEmpresa) =>{
    var empresa="";
    for (var _empresa in empreses.allEmpresa.nodes){
        if (empreses.allEmpresa.nodes[_empresa].idEmpresa==idEmpresa) {
          empresa=empreses.allEmpresa.nodes[_empresa]
          break;
        }
    }
    return empresa;
  }

  const handleConfirm = (e) => {
    e.preventDefault();
    setLoading(true)
    axios.defaults.headers.common['Authorization'] =  getUser().token;
    formData.delete("observacions")
    formData.delete("titol")
    formData.delete("dataInspeccio")
    formData.delete("granja")
    formData.append("observacions",data.observacions)
    formData.append("titol",data.titol)
    formData.append("dataInspeccio",data.dataInspeccio)
    console.log("location "+data.location)

    formData.append("location",data.location)
    formData.append("granja",data.granja)

    axios({
      method: "post",
      url: "/auth/insertVisita",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    }).then(res => {
      setLoading(false)
      if (res.data.error) {
       console.log("ERROR AL INSERTAR = "+JSON.stringify(res.data) )   
       setMessageAlertStep(res.data.error)
       setShowAlertStep(true)
      } else{
        window.localStorage.removeItem("userGranjaTecVisites")
        removeItem("userGranjaTecVisites")
        navigate("/mainMenu")
      }
   }).catch(error => {
      setMessageAlertStep(error.message)
      setShowAlertStep(true)
      setLoading(false)
   })  
  }

  useEffect( () => {
    if (!getUser() || getUser().nivell.veterinari!==1) {
      navigate("../../mainMenu") 
    }
    var empresa=""
    if (getUser()) empresa=getEmpresa(getUser().idEmpresa);
    setFamilies(empresa.families)
    initVisites();
    }, []);

  return (
      <React.Fragment>
        <div className="granjatec-wrapper">
          <Header/>
          <HeaderMenu />
          <div className="main-container">
              <section className="section heading-opt">
                <div className="heading-opt-title">
                  <h2 className="h5">Families</h2>
                </div>
              </section>
              <section className="section form-opt destete-engreix">
                <form className="steps">
                  <TransitionFamilies index={1} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        arrayData={families}
                        callbackValues={getSubFamilies}
                        />
                  <TransitionSubFamilies index={2} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        arrayData={familia}
                        />
                   <TransitionInspeccio index={3} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        arrayData={familia}
                        callbackValues={handleFormData}
                        />
                     <TransitionInspeccioResum index={4} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        handleStepActiveDown={handleStepDown}
                        resetStepActive={resetStepActive}
                        arrayData={data}
                        handleConfirm={handleConfirm}
                      />

                </form>
              </section>
              { isLoading && (
              <div className="overlay-loading"></div>
              )}
            </div>
            <CSSTransition
              in={showAlertStep}
              timeout={300}
              classNames={'alertright'}
              unmountOnExit >
              <Alert type="error">
                <div className="alert-message">
                  {messageAlertStep}
                </div>
                <span className="alert-close" onClick={closeAlertStep}>
                  <i className="icon-close"></i>
                </span>
              </Alert>
            </CSSTransition>
          <Footer/>
        </div>
    </React.Fragment>

)}

export default Visita